import React from "react";
import {
  // Card,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Label,
  // Rail,
  Rating,
  // Responsive,
  Segment
} from "semantic-ui-react";

import BG from "../Images/Services/BGtransFull.png";

import SV4 from "../Images/Services/SV4.jpg";
import SV5 from "../Images/Services/SV5.jpg";
import SV6 from "../Images/Services/SV6.jpg";

export default function ServicesTwo() {
  return (
    <div
      style={{
        background: "#eee",
        backgroundImage: `url(${BG})`,
        backgroundRepeat: "no-repeat",
        paddingBottom: 50
      }}
    >
      <Container style={{ padding: "1em 0em" }}>
        {/* <Grid.Column width={16} style={{ height: 98 }}></Grid.Column> */}
        <Divider
          as="h1"
          className="header"
          horizontal
          style={{
            color: "#FF8C00",
            fontFamily: "gadugi",
            padding: "1em 0em 1.5em 0em",
            textTransform: "uppercase",
            margin: 0
          }}
        >
          Our Services
        </Divider>
        <Segment
          style={{
            margin: "0em 5em 2em 0em",
            minHeight: 340,
            textAlign: "justify"
          }}
          raised
        >
          <Header as="h2" floated="left" style={{ marginTop: 6 }}>
            Warehouse and Distribution
          </Header>
          {/* <Label
            color="orange"
            ribbon="right"
            size="big"
            style={{ margin: "auto", left: "72.8%" }}
          >
            <Rating icon="star" defaultRating={5} maxRating={5} disabled />
          </Label> */}
          {/* </div> */}

          <Divider clearing />
          <Image
            src={SV4}
            size="medium"
            floated="left"
            style={{ borderRadius: 5 }}
          />
          <p style={{ fontSize: 22 }}>
            We provide you with one stop logistic sservices from procurement to
            sales and will control your whole supply chain as your partner. We
            provide high grade 3PL services as an integrated logistics company.
            We have an abundance of assets and know-how from long time
            experience. Providing value-added services for wide range of
            distribution process including inspection,
          </p>
        </Segment>
        <Segment
          style={{
            margin: "0em 0em 2em 5em",
            minHeight: 340,
            textAlign: "justify"
          }}
        >
          <Header as="h2" floated="left" style={{ marginTop: 6 }}>
            Fault Repair Management or Order Management
          </Header>
          {/* <Label
            color="orange"
            ribbon="right"
            size="big"
            style={{ margin: "auto", left: "51.5%" }}
          >
            <Rating icon="star" defaultRating={5} maxRating={5} disabled />
          </Label> */}
          <Divider clearing />
          <Image
            src={SV5}
            size="medium"
            floated="right"
            style={{ borderRadius: 5 }}
          />
          <p style={{ fontSize: 22 }}>
            Our FRC/OM expert to help you optimize and manage all the components
            of your inventory operations. Armed by SAP system as used by all
            global companies. Which sharpen the accuracy of our database to
            prevent mismatch and to ensure your spare part in excellent
            condition. Our FRC/OM team are qualified internationally. They have
            been handling se.
          </p>
        </Segment>
        <Segment
          style={{
            margin: "0em 5em 2em 0em",
            minHeight: 340,
            textAlign: "justify"
          }}
          raised
        >
          <Header as="h2" floated="left" style={{ marginTop: 6 }}>
            Testbad or Prescreening
          </Header>
          {/* <Label
            color="orange"
            ribbon="right"
            size="big"
            style={{ margin: "auto", left: "77.3%" }}
          >
            <Rating icon="star" defaultRating={5} maxRating={5} disabled />
          </Label> */}
          {/* </div> */}

          <Divider clearing />
          <Image
            src={SV6}
            size="medium"
            floated="left"
            style={{ borderRadius: 5 }}
          />
          <p style={{ fontSize: 22 }}>
            Extensive experiences in field of telecommunication are strengths in
            screening spareparts. Particularly for 2G and 4G network. Our
            engineers expertise physical checks and installation for
            telecommunications spare parts is qualified and reliable.
          </p>
        </Segment>
      </Container>
    </div>
  );
}

// const imageFrame = {
//   boxShadow: "0px 10px 30px -10px rgba(0, 0, 0, 0.3)"
// };
