import React, { useEffect } from "react";
import {
  // Button,
  // Container,
  // Divider,
  // Grid,
  // Image,
  // Reveal,
  // Header,
  Segment
} from "semantic-ui-react";

import { CarouselHeader, OurServices, CardManagement } from "../Modules";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <Segment
      inverted
      textAlign="center"
      style={{
        minHeight: 900,
        backgroundColor: "#eeeeee",
        padding: 0,
        margin: 0,
        color: "#000"
      }}
    >
      <CarouselHeader />
      <OurServices />
      <CardManagement />
    </Segment>
  );
}
