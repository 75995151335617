import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Responsive, Image } from "semantic-ui-react";

import SH1 from "../Images/SlideHeader/SH1.png";
import SH2 from "../Images/SlideHeader/SH2.jpg";
import SH3 from "../Images/SlideHeader/SH3.png";

const CarouselHeader = () => {
  return (
    <Carousel
      emulateTouch
      autoPlay
      infiniteLoop
      showThumbs={false}
      showStatus={false}
    >
      <div>
        <Image src={SH1} />
        <Responsive minWidth={1200} maxWidth={2559}>
          <span
            className="legend"
            style={{
              textAlign: "left",
              width: "30%",
              left: "110%",
              top: "73%",
              // bottom: "17%",
              fontFamily: "gadugi"
            }}
          >
            <h2 as="h2" style={{ fontFamily: "gadugi" }}>
              Solution For Your Inventory
            </h2>
            <span style={{ fontSize: "1.3rem", fontFamily: "gadugi" }}>
              <p>
                We ensure your inventory at the right place, at the right time.
              </p>
            </span>
          </span>
        </Responsive>
      </div>
      <div>
        <Image src={SH2} />
        <Responsive minWidth={1200} maxWidth={2559}>
          <span
            className="legend"
            style={{
              textAlign: "left",
              width: "50%",
              fontFamily: "gadugi"
            }}
          >
            <h2 style={{ fontFamily: "gadugi" }}>
              Solution For Your System Inventory
            </h2>
            <span style={{ fontSize: "1.3rem", fontFamily: "gadugi" }}>
              <p>
                We create your customize system in field of inventory and
                logistics using supply chain management.
              </p>
            </span>
          </span>
        </Responsive>
      </div>
      <div>
        <Image src={SH3} />
        <Responsive minWidth={1200} maxWidth={2559}>
          <span
            className="legend"
            style={{
              textAlign: "left",
              width: "28%",
              left: "113%",
              top: "60%",
              bottom: "14%",
              fontFamily: "gadugi"
            }}
          >
            <h2 style={{ fontFamily: "gadugi" }}>Real Time Monitoring</h2>
            <span style={{ fontSize: "1.3rem", fontFamily: "gadugi" }}>
              <p>
                Your progress monitoring now can be checked in real time, can be
                accessed mobile anytime, anywhere.
              </p>
            </span>
          </span>
        </Responsive>
      </div>
    </Carousel>
  );
};

export default CarouselHeader;
