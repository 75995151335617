import React, { useEffect } from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";

const position = [-6.961035, 107.640561];

function LeafletMap() {
  return (
    <Map center={position} zoom={17}>
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
    />
    <Marker position={position}>
      <Popup>
        Harrasima Inventory Logistic
        <br />
        <a href="https://www.google.co.id/maps/place/HARRASIMA+INVENTORY+LOGISTICS/@-6.9608064,107.6347799,15.25z/data=!4m5!3m4!1s0x2e68e9deb1ff4369:0xeaf4635e8125246d!8m2!3d-6.9610422!4d107.6405506" target="_blank">here</a> to google map.
      </Popup>
    </Marker>
  </Map>
  );
}

export default LeafletMap;
