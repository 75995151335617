import React from "react";
import { Image } from "semantic-ui-react";

// import imgHIL from "../Images/Logo/HIL.png";
// import imgHIL2 from "../Images/Logo/HIL2.png";
import imgHIL2Lan from "../Images/Logo/HIL-Landscape.png";

export default function LogoNav() {
  // const [img, setImg] = useState(imgHIL2Lan);
  // const [scroll, setScroll] = useState(0);

  // const handleScroll = e => {
  //   // console.log(window.scrollY);
  //   console.log(scroll);
  //   if (window.scrollY >= scroll) {
  //     setImg(imgHIL2);
  //   } else {
  //     setImg(imgHIL2);
  //   }
  //   console.log(img);
  // };

  return (
    <Image
      src={imgHIL2Lan}
      style={{ width: "140px", height: "45px", marginLeft: "0px" }}
    />
  );
}
