import React from "react";
import Map from "pigeon-maps";
import Marker from "pigeon-marker";
// import Overlay from "pigeon-overlay";

const PigeonMap = (
  <Map center={[-6.961035, 107.640561]} zoom={16}>
    <Marker
      anchor={[-6.961035, 107.640561]}
      payload={1}
      onClick={({ event, anchor, payload }) => {
        console.log(`Marker #${payload} clicked at: `, anchor);
        window.open(
          "https://www.google.co.id/maps/place/HARRASIMA+INVENTORY+LOGISTICS/@-6.9608064,107.6347799,15.25z/data=!4m5!3m4!1s0x2e68e9deb1ff4369:0xeaf4635e8125246d!8m2!3d-6.9610422!4d107.6405506",
          "_blank"
        );
      }}
    />
  </Map>
);

export default PigeonMap;
