import React, { useState, useEffect } from "react";
import * as emailjs from "emailjs-com";
import Recaptcha from "react-recaptcha";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Modal,
  Responsive,
  Segment,
} from "semantic-ui-react";
import Flip from "react-reveal/Flip";
// import MapContainer from "../Modules/Google/Maps.js";
import PigeonMap from "../Modules/Maps/PigeonMap.js";
import LeafletMap from "../Modules/Maps/LeafletMap.js";

import Cot from "../Images/Contact/Contact1.jpg";

import "../Styles/styleLeafLet.css";

const options = [
  { key: "m", text: "Male", value: "male" },
  { key: "f", text: "Female", value: "female" },
  { key: "o", text: "Other", value: "other" },
];

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [val, setVal] = useState(true);
  const [verified, setVerified] = useState(false);
  const [succOpen, setSuccOpen] = useState(false);
  const [failOpen, setFailOpen] = useState(false);
  const [formContact, setFormContact] = useState({
    firstName: "-",
    lastName: "-",
    companyName: "-",
    companyAdd: "-",
    email: "-",
    phone: "-",
    mobile: "-",
    message: "-",
    emailValid: false,
    messageValid: false,
    focusErr: { mail: false, mess: false },
  });

  const updateField = (e, { name, value }) => {
    let emailValid = formContact.emailValid;
    let messageValid = formContact.messageValid;
    let fieldValidationFocus = formContact.focusErr;
    switch (name) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationFocus.mail = emailValid ? false : true;
        break;

      case "message":
        messageValid = value.length >= 6;
        fieldValidationFocus.mess = messageValid ? false : true;
        break;

      default:
        break;
    }

    setFormContact({
      ...formContact,
      [name]: value,
      focusErr: fieldValidationFocus,
      emailValid: emailValid,
      messageValid: messageValid,
    });

    if (emailValid && messageValid) {
      setVal(false);
    } else {
      setVal(true);
    }
  };

  const recaptchaLoaded = () => {
    console.log("cap sukses");
  };

  const sendSubscribe = () => {
    const {
      firstName,
      lastName,
      companyName,
      companyAdd,
      email,
      phone,
      mobile,
      message,
    } = formContact;

    if (verified) {
      // alert("sukses");
      let templateParams = {
        subject: "Interest in Cooperation",
        from_name: firstName + " " + lastName,
        from_address: companyAdd,
        from_mail: email,
        from_company: companyName,
        from_phone: phone,
        from_mobile: mobile,
        message_html: message,
      };

      emailjs.send(
        "marketing_hil",
        "template_1afovZxb",
        templateParams,
        "user_F1KMegUloF6kV34RYnbG6"
      );

      // emailjs.send("codesadmin", "template_1afovZxb", {
      //   message_html: message,
      //   from_address: companyAdd,
      //   from_mail: email,
      //   from_company: companyName,
      //   from_name: firstName + " " + lastName,
      //   to_name: "HIL",
      //   from_phone: phone,
      //   user_name: firstName + " " + lastName,
      //   from_mobile: mobile
      // });

      // resetForm();
      handleSuccOpen();
    } else {
      handleFailOpen();
    }
  };

  const resetForm = () => {
    setFormContact({
      firstName: "-",
      lastName: "-",
      companyName: "-",
      companyAdd: "-",
      email: "-",
      phone: "-",
      mobile: "-",
      message: "-",
    });
  };

  const handleSuccOpen = () => {
    setSuccOpen(true);
  };

  const handleSuccClose = () => {
    setSuccOpen(false);
  };

  const handleFailOpen = () => {
    setFailOpen(true);
  };

  const handleFailClose = () => {
    setFailOpen(false);
  };

  const verifyCallback = (response) => {
    if (response) {
      setVerified(true);
    }
  };

  // console.log(formContact.message);
  return (
    <div
      style={{
        background: "white",
        backgroundRepeat: "no-repeat",
        paddingBottom: 50,
      }}
    >
      <Responsive minWidth={755} maxWidth={2559}>
        <Container style={{ padding: "4em 0em 0em" }}>
          <Segment raised>
            <Grid columns={2}>
              <Grid.Column
                width={6}
                style={{ textAlign: "justify", textJustify: "inter-word" }}
              >
                <Header
                  as="h1"
                  style={{ color: "#FF8C00", fontFamily: "gadugi" }}
                >
                  Contact Us
                </Header>
                <Form>
                  <Form.Input
                    name="firstName"
                    label="First Name"
                    placeholder="First Name"
                    onChange={updateField}
                  />
                  <Form.Input
                    name="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                    onChange={updateField}
                  />
                  <Form.Input
                    name="companyName"
                    label="Company Name"
                    placeholder="Company Name"
                    onChange={updateField}
                  />
                  <Form.Input
                    name="companyAdd"
                    label="Company Address"
                    placeholder="Company Address"
                    onChange={updateField}
                  />
                  <Form.Input
                    name="email"
                    label="Email *"
                    placeholder="Email"
                    onChange={updateField}
                    error={formContact.focusErr.mail}
                  />
                  <Form.Input
                    name="phone"
                    label="Phone"
                    placeholder="Phone"
                    onChange={updateField}
                  />
                  <Form.Input
                    name="mobile"
                    label="Mobile"
                    placeholder="Mobile"
                    onChange={updateField}
                  />
                  <Form.TextArea
                    name="message"
                    label="Message *"
                    placeholder="Tell us more..."
                    onChange={updateField}
                    error={formContact.focusErr.mess}
                  />
                  <Label
                    style={{
                      backgroundColor: "transparent",
                      float: "left",
                      marginBottom: "1em",
                      paddingLeft: 0,
                    }}
                  >
                    (*) must fills
                  </Label>
                  <Form.Button
                    primary
                    type="submit"
                    onClick={sendSubscribe}
                    style={{ marginBottom: 20 }}
                    disabled={val}
                  >
                    Subscribe
                  </Form.Button>

                  <Modal
                    open={succOpen}
                    onClose={handleSuccClose}
                    basic
                    size="small"
                  >
                    <Header icon="check circle outline" content="Subscribe" />
                    <Modal.Content>
                      <h3>
                        You have successfully made subscribe Harrasima Inventory
                        Logistic.
                      </h3>
                    </Modal.Content>
                    <Modal.Actions>
                      <Link to="/home">
                        <Button color="green" onClick={handleSuccClose} inverted>
                          <Icon name="checkmark" /> Got it
                        </Button>
                      </Link>
                    </Modal.Actions>
                  </Modal>

                  <Modal
                    open={failOpen}
                    onClose={handleFailClose}
                    basic
                    size="small"
                  >
                    <Header icon="times circle outline" content="Subscribe" />
                    <Modal.Content>
                      <h3>You are Robot, sorry..</h3>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button color="red" onClick={handleFailClose} inverted>
                        <Icon name="checkmark" /> Got it
                      </Button>
                    </Modal.Actions>
                  </Modal>

                  <Recaptcha
                    sitekey="6Lf6V-YUAAAAAHH2oEJ2OUewF5vDBFhHBFXkHB5V"
                    render="explicit"
                    onloadCallback={recaptchaLoaded}
                    verifyCallback={verifyCallback}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column width={10}>
                <Flip top>
                  <Image src={Cot} rounded />
                </Flip>
                <Header
                  as="h2"
                  style={{
                    color: "#FF8C00",
                    fontFamily: "gadugi",
                    // marginBottom: "2em",
                    textAlign: "center",
                  }}
                >
                  PT Harrasima Inventory Logistic
                </Header>

                <Card.Group centered>
                  <Card>
                    <Card.Content>
                      <Card.Header>Head Office</Card.Header>
                      <Card.Meta>Ruko Buah Batu Regency</Card.Meta>
                      <Card.Description>
                        Jl. Terusan Buah Batu Blok A2 <br />
                        No. 7-8 RT10 RW07
                        <br />
                        Kel. Kujang Sari <br />
                        Kec. Bandung Kidul Buah Batu, Bandung <br /> Indonesia
                        40287
                      </Card.Description>
                    </Card.Content>
                  </Card>

                  <Card>
                    <Card.Content>
                      <Card.Header>Central Warehouse</Card.Header>
                      <Card.Meta>Komplek Pergudangan Detta Marina</Card.Meta>
                      <Card.Description>
                        Jl. Raya Bogor <br /> KM28 RT06 RW02
                        <br />
                        Pekayon, Pasar Rebo
                        <br />
                        Jakarta Timur, DKI Jakarta
                        <br />
                        Indonesia 13710
                      </Card.Description>
                    </Card.Content>
                  </Card>

                  <Card>
                    <Card.Content>
                      <Card.Header>Customer Services</Card.Header>
                      <Card.Meta>callcenter@wmslog.com</Card.Meta>
                      <Card.Description>
                        Call Center : 022-87309959
                      </Card.Description>
                    </Card.Content>
                  </Card>

                  <Card>
                    <Card.Content>
                      <Card.Header>Sales & Marketing</Card.Header>
                      <Card.Meta>marketing@harrasima.co.id</Card.Meta>
                      <Card.Description>
                        Phone : 021-28566128 <br />
                        SMS/WA : 089625071413
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Card.Group>
              </Grid.Column>
            </Grid>
            <Divider vertical clearing style={{ left: "37.5%" }}>
              |
            </Divider>
          </Segment>
          <Segment style={{ marginTop: 30, width: "100%", height: 300 }}>
            {/* <MapContainer /> */}
            {/* {PigeonMap} */}
            <LeafletMap />
          </Segment>
        </Container>
      </Responsive>
      <Responsive minWidth={300} maxWidth={755}>
        <Container style={{ padding: "1em 0em 0em" }}>
          <Segment raised>
            <Grid columns={1}>
              <Grid.Column
                width={16}
                style={{ textAlign: "justify", textJustify: "inter-word" }}
              >
                <Header
                  as="h1"
                  style={{ color: "#FF8C00", fontFamily: "gadugi" }}
                >
                  Contact Us
                </Header>
                <Form>
                  <Form.Input
                    name="firstName"
                    label="First Name"
                    placeholder="First Name"
                    onChange={updateField}
                  />
                  <Form.Input
                    name="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                    onChange={updateField}
                  />
                  <Form.Input
                    name="companyName"
                    label="Company Name"
                    placeholder="Company Name"
                    onChange={updateField}
                  />
                  <Form.Input
                    name="companyAdd"
                    label="Company Address"
                    placeholder="Company Address"
                    onChange={updateField}
                  />
                  <Form.Input
                    name="email"
                    label="Email *"
                    placeholder="Email"
                    onChange={updateField}
                    error={formContact.focusErr.mail}
                  />
                  <Form.Input
                    name="phone"
                    label="Phone"
                    placeholder="Phone"
                    onChange={updateField}
                  />
                  <Form.Input
                    name="mobile"
                    label="Mobile"
                    placeholder="Mobile"
                    onChange={updateField}
                  />
                  <Form.TextArea
                    name="message"
                    label="Message *"
                    placeholder="Tell us more..."
                    onChange={updateField}
                    error={formContact.focusErr.mess}
                  />
                  <Label
                    style={{
                      backgroundColor: "transparent",
                      float: "left",
                      marginBottom: "1em",
                      paddingLeft: 0,
                    }}
                  >
                    (*) must fills
                  </Label>
                  <Form.Button
                    primary
                    type="submit"
                    onClick={sendSubscribe}
                    style={{ marginBottom: 20 }}
                    disabled={val}
                  >
                    Subscribe
                  </Form.Button>

                  <Modal
                    open={succOpen}
                    onClose={handleSuccClose}
                    basic
                    size="small"
                  >
                    <Header icon="check circle outline" content="Subscribe" />
                    <Modal.Content>
                      <h3>
                        You have successfully made subscribe Harrasima Inventory
                        Logistic.
                      </h3>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button color="green" onClick={handleSuccClose} inverted>
                        <Icon name="checkmark" /> Got it
                      </Button>
                    </Modal.Actions>
                  </Modal>

                  <Modal
                    open={failOpen}
                    onClose={handleFailClose}
                    basic
                    size="small"
                  >
                    <Header icon="times circle outline" content="Subscribe" />
                    <Modal.Content>
                      <h3>You are Robot, sorry..</h3>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button color="red" onClick={handleFailClose} inverted>
                        <Icon name="checkmark" /> Got it
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </Form>
              </Grid.Column>

              <Grid.Column width={16}>
                <Flip top>
                  <Image src={Cot} rounded />
                </Flip>
                <Header
                  as="h2"
                  style={{
                    color: "#FF8C00",
                    fontFamily: "gadugi",
                    textAlign: "center",
                  }}
                >
                  PT Harrasima Inventory Logistic
                </Header>

                <Card.Group centered>
                  <Card>
                    <Card.Content>
                      <Card.Header>Head Office</Card.Header>
                      <Card.Meta>Ruko Buah Batu Regency</Card.Meta>
                      <Card.Description>
                        Jl. Terusan Buah Batu Blok A2 <br />
                        No. 7-8 RT10 RW07
                        <br />
                        Kel. Kujang Sari <br />
                        Kec. Bandung Kidul Buah Batu, Bandung <br /> Indonesia
                        40287
                      </Card.Description>
                    </Card.Content>
                  </Card>

                  <Card>
                    <Card.Content>
                      <Card.Header>Central Warehouse</Card.Header>
                      <Card.Meta>Komplek Pergudangan Detta Marina</Card.Meta>
                      <Card.Description>
                        Jl. Raya Bogor <br /> KM28 RT06 RW02
                        <br />
                        Pekayon, Pasar Rebo
                        <br />
                        Jakarta Timur, DKI Jakarta
                        <br />
                        Indonesia 13710
                      </Card.Description>
                    </Card.Content>
                  </Card>

                  <Card>
                    <Card.Content>
                      <Card.Header>Customer Services</Card.Header>
                      <Card.Meta>callcenter@wmslog.com</Card.Meta>
                      <Card.Description>
                        Call Center : 022-87309959
                      </Card.Description>
                    </Card.Content>
                  </Card>

                  <Card style={{ marginBottom: "2em" }}>
                    <Card.Content>
                      <Card.Header>Sales & Marketing</Card.Header>
                      <Card.Meta>marketing@harrasima.co.id</Card.Meta>
                      <Card.Description>
                        Phone : 021-28566128 <br />
                        SMS/WA : 089625071413
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Card.Group>
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment style={{ marginTop: 30, width: "100%", height: 300 }}>
            {/* <MapContainer /> */}
            <LeafletMap />
          </Segment>
        </Container>
      </Responsive>
    </div>
  );
}
