import React from "react";
import {
  Card,
  // Container,
  Divider,
  Header,
  Grid,
  Icon,
  Image,
  Label,
  // Segment,
  // Message
} from "semantic-ui-react";
import OrgChart from "@capricasoftware/react-org-chart";

import SO1 from "../Images/StructuralOrg/SO1.jpg";
import SO2 from "../Images/StructuralOrg/SO2.jpg";
import SO3 from "../Images/StructuralOrg/SO3.jpg";
import SO4 from "../Images/StructuralOrg/SO4.jpg";

export default function OrganizationChart() {
  const ChartOne = () => {
    return (
      <OrgChart>
        <OrgChart.Node>
          <Image src={SO1} rounded />
          <Divider />
          <Header as="h3" style={{ marginTop: -5, marginBottom: 0 }}>
            Mursid Lubis
          </Header>
          <p>COMMISIONER</p>
          <OrgChart.Node>
            <Image src={SO2} rounded />
            <Divider />
            <Header as="h3" style={{ marginTop: -5, marginBottom: 0 }}>
              Erna Saptariah
            </Header>
            <p>CEO</p>
          </OrgChart.Node>
          <OrgChart.Node>
            <Image src={SO3} rounded />
            <Divider />
            <Header as="h3" style={{ marginTop: -5, marginBottom: 0 }}>
              Ademina Karmina
            </Header>
            <p>OPERATION DIRECTOR</p>
          </OrgChart.Node>
          <OrgChart.Node>
            <Image src={SO4} rounded />
            <Divider />
            <Header as="h3" style={{ marginTop: -5, marginBottom: 0 }}>
              Neni Triana
            </Header>
            <p>FINANCE DIRECTOR</p>
          </OrgChart.Node>
        </OrgChart.Node>
      </OrgChart>
    );
  };

  const ChartTwo = () => {
    return (
      <Grid centered columns={4} style={{ marginBottom: 30 }}>
        <Grid.Column>
          <Card raised style={{ margin: "auto" }}>
            <Image src={SO1} wrapped ui={false} />
            <Card.Content style={{ textAlign: "center" }}>
              <Card.Header>Mursid Lubis</Card.Header>
              <Card.Meta>
                <span className="date">COMMISIONER</span>
              </Card.Meta>
            </Card.Content>
          </Card>
        </Grid.Column>

        <Grid.Row centered columns={4}>
          <Grid.Column>
            <Card raised style={{ margin: "auto" }}>
              <Image src={SO2} wrapped ui={false} />
              <Card.Content style={{ textAlign: "center" }}>
                <Card.Header>Erna Saptariah</Card.Header>
                <Card.Meta>
                  <span className="date">CEO</span>
                </Card.Meta>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card raised style={{ margin: "auto" }}>
              <Image src={SO3} wrapped ui={false} />
              <Card.Content style={{ textAlign: "center" }}>
                <Card.Header>Ademina Karmina</Card.Header>
                <Card.Meta>
                  <span className="date">OPERATION DIRECTOR</span>
                </Card.Meta>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card raised style={{ margin: "auto" }}>
              <Image src={SO4} wrapped ui={false} />
              <Card.Content style={{ textAlign: "center" }}>
                <Card.Header>Neni Triana</Card.Header>
                <Card.Meta>
                  <span className="date">FINANCE DIRECTOR</span>
                </Card.Meta>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };
  return <ChartTwo />;
}
