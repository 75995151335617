import React from "react";
import {
  Card,
  Container,
  // Divider,
  // Header,
  Grid,
  // Icon,
  Image
  // List,
  // Segment,
  // Message
} from "semantic-ui-react";

import SO1 from "../Images/StructuralOrg/SO1.jpg";
import SO2 from "../Images/StructuralOrg/SO2.jpg";
import SO3 from "../Images/StructuralOrg/SO3.jpg";
import SO4 from "../Images/StructuralOrg/SO4.jpg";

export default function OrganizationCard() {
  return (
    <Container style={{ padding: "0em 0em" }}>
      <Grid
        stackable
        divided="vertically"
        style={{ color: "#FFF", textAlign: "left", padding: "1em 0em" }}
      >
        <Grid.Row centered columns={3}>
          <Grid.Column>
            <Card style={{ margin: "auto", textAlign: "center" }}>
              <Image
                src={SO1}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header>Mursid Lubis</Card.Header>
                <Card.Meta>
                  <span className="date">COMMISIONER</span>
                </Card.Meta>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered columns={3}>
          <Grid.Column>
            <Card style={{ margin: "auto", textAlign: "center" }}>
              <Image
                src={SO2}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header>Erna Saptariah</Card.Header>
                <Card.Meta>
                  <span className="date">CEO</span>
                </Card.Meta>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card style={{ margin: "auto", textAlign: "center" }}>
              <Image
                src={SO3}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header>Ademina Karmina</Card.Header>
                <Card.Meta>
                  <span className="date">OPERATION DIRECTOR</span>
                </Card.Meta>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card style={{ margin: "auto", textAlign: "center" }}>
              <Image
                src={SO4}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header>Neni Triana</Card.Header>
                <Card.Meta>
                  <span className="date">FINANCE DIRECTOR</span>
                </Card.Meta>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
