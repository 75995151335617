import React from "react";
import {
  Button,
  Container,
  // Divider,
  Grid,
  Header,
  // Icon,
  Image,
  // List,
  Menu,
  Segment,
  // Sidebar,
  // Visibility
} from "semantic-ui-react";
// import { Link } from "react-router-dom";

import imgHIL2 from "../Images/Logo/HIL2.png";

export default function Footer() {
  return (
    <Segment
      inverted
      vertical
      style={{ padding: "1em 0em", backgroundColor: "#FF8C00" }}
    >
      <Container>
        <Grid inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Menu.Menu style={{ paddingTop: "10%" }}>
                <Menu.Item>
                  <Header
                    inverted
                    as="h5"
                    content="Follow HIL"
                    style={{ fontFamily: "gadugi", textAlign: "center" }}
                  />
                </Menu.Item>
              </Menu.Menu>
            </Grid.Column>
            <Grid.Column width={10}>
              <Menu.Menu
                position="right"
                style={{
                  fontFamily: "gadugi",
                  paddingTop: "1%",
                  textAlign: "center",
                }}
              >
                <Menu.Item as="div">
                  <Button
                    circular
                    icon="facebook f"
                    style={{ marginRight: 10, marginLeft: 10, backgroundColor: "white" }}
                    href="https://web.facebook.com/harrasima.id/?notify_field=blurb&modal=profile_completion&notif_id=1586662642191929&notif_t=page_profile_blurb"
                    target="_blank"
                  />
                  <Button
                    circular
                    icon="twitter"
                    style={{ marginRight: 10, marginLeft: 10, backgroundColor: "white" }}
                    href="https://twitter.com/sales_hil"
                    target="_blank"
                  />
                  <Button
                    circular
                    icon="linkedin"
                    style={{ marginRight: 10, marginLeft: 10, backgroundColor: "white" }}
                    href="https://www.linkedin.com/in/harrasima-inventory-logistic-1770831a7/"
                    target="_blank"
                  />
                  <Button
                    circular
                    icon="instagram"
                    style={{ marginRight: 10, marginLeft: 10, backgroundColor: "white" }}
                    href="https://www.instagram.com/harrasima/"
                    target="_blank"
                  />
                  <Button
                    circular
                    icon="youtube"
                    style={{ marginRight: 10, marginLeft: 10, backgroundColor: "white" }}
                    href="https://www.youtube.com/channel/UCl820YIOZ74Hu6xKsIaWLeg?view_as=subscriber"
                    target="_blank"
                  />
                </Menu.Item>
              </Menu.Menu>
            </Grid.Column>
            <Grid.Column width={3} position="right">
              <Image
                src={imgHIL2}
                style={{ width: "45px", height: "45px", margin: "auto" }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
}
