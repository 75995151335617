import React, { useEffect } from "react";
import {
  Container,
  Divider,
  Header,
  Grid,
  Icon,
  Image,
  List,
  Responsive,
  Segment,
  Message
} from "semantic-ui-react";
import Flip from "react-reveal/Flip";

// import SH1 from "../Images/SlideHeader/SH1.png";
import About from "../Images/About/About.jpg";
import AboutHeader from "../Images/About/Header1.jpg";
import map from "../Images/About/Map.jpg";
// const src = "https://react.semantic-ui.com/images/avatar/large/matthew.png";

const mission = [
  "Leading in Inventory Management System.",
  "Professional and focus on the Spare Part Management Services.",
  "Trusted and reliable partner in the SLA based services."
];

export default function AboutOne() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <>
      <div style={{ backgroundImage: `url(${AboutHeader})` }}>
        <Container style={{ padding: "3em 0em" }}>
          <Segment raised>
            <Responsive minWidth={755} maxWidth={2559}>
              <Grid columns={2}>
                <Grid.Column
                  width={12}
                  style={{ textAlign: "justify", textJustify: "inter-word" }}
                >
                  <Header
                    as="h1"
                    style={{ color: "#FF8C00", fontFamily: "gadugi" }}
                  >
                    About HIL
                  </Header>
                  <p style={{ fontSize: 22 }}>
                    PT.Harrasima Inventory Logistic (HIL) is a company engaged
                    in area of supply chain management solution. Established
                    since 2015 in Bandung, Indonesia, we provide an integrated
                    services which tailored well to streamline our customer’s
                    inventory through best-in-class services, processes.
                  </p>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Flip top>
                    <Image src={About} rounded />
                  </Flip>
                </Grid.Column>
              </Grid>
            </Responsive>
            <Responsive minWidth={300} maxWidth={755}>
              <Grid>
                <Grid.Column
                  width={16}
                  style={{ textAlign: "justify", textJustify: "inter-word" }}
                >
                  <Header
                    as="h1"
                    style={{ color: "#FF8C00", fontFamily: "gadugi" }}
                  >
                    About HIL
                  </Header>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Flip top>
                    <Image src={About} rounded />
                  </Flip>
                </Grid.Column>
                <Grid.Column
                  width={16}
                  style={{ textAlign: "justify", textJustify: "inter-word" }}
                >
                  <p style={{ fontSize: 22 }}>
                    PT.Harrasima Inventory Logistic (HIL) is a company engaged
                    in area of supply chain management solution. Established
                    since 2015 in Bandung, Indonesia, we provide an integrated
                    services which tailored well to streamline our customer’s
                    inventory through best-in-class services, processes.
                  </p>
                </Grid.Column>
              </Grid>
            </Responsive>
          </Segment>
        </Container>
      </div>
      <div style={{ background: "#EEE" }}>
        <Container
          style={{
            padding: "3em 0em",
            textAlign: "left",
            alignItems: "flex-start"
          }}
        >
          <Message
            icon
            style={{
              background: "#EEE",
              boxShadow: "none",
              alignItems: "flex-start"
            }}
          >
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>
                <Header as="h1" style={{ color: "#FF8C00" }}>
                  VISION
                </Header>
              </Message.Header>
              <p style={{ fontSize: 17 }}>
                Become leading company in the areas of supply chain and we
                continue to further develop our position a world class company.
              </p>
            </Message.Content>
          </Message>
          <Message
            icon
            style={{
              background: "#EEE",
              boxShadow: "none",
              alignItems: "flex-start"
            }}
          >
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>
                <Header as="h1" style={{ color: "#FF8C00" }}>
                  MISSION
                </Header>
              </Message.Header>
              <Message.List items={mission} style={{ fontSize: 17 }} />
            </Message.Content>
          </Message>
        </Container>
      </div>
      <div style={{ background: "#FFF" }}>
        <Container style={{ padding: "1em 0em" }}>
          <Responsive minWidth={755} maxWidth={2559}>
            <Divider
              as="h1"
              className="header"
              horizontal
              style={{
                color: "#FF8C00",
                fontFamily: "gadugi",
                padding: "1em 0em 0em 0em",
                textTransform: "uppercase",
                margin: 0
              }}
            >
              Warehouse Covering Map
            </Divider>
          </Responsive>
          <Responsive minWidth={300} maxWidth={755}>
            <Divider
              as="h2"
              className="header"
              horizontal
              style={{
                color: "#FF8C00",
                fontFamily: "gadugi",
                padding: "1em 0em 0em 0em",
                textTransform: "uppercase",
                margin: 0
              }}
            >
              Warehouse Covering Map
            </Divider>
          </Responsive>
        </Container>
        <Image src={map} rounded />
      </div>
      <div style={{ background: "#FF8C00" }}>
        <Container style={{ padding: "1em 0em", background: "#FF8C00" }}>
          <Responsive minWidth={755} maxWidth={2559}>
            <Grid
              stackable
              columns={5}
              style={{ color: "#FFF", textAlign: "left", padding: "1em 0em" }}
            >
              <Grid.Column>
                <List style={{ padding: "0px 10px" }}>
                  <List.Item style={{ padding: "10px 0px" }}>
                    1. AMBON
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    2. BALIKPAPAN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    3. BANDUNG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    4. BANJARMASIN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    5. BATAM
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    6. BATULICIN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    7. BENGKULU
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>8. BIMA</List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    9. BOGOR
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    10. BUKITTINGGI
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    11. BUNTOK
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    12. CIKAMPEK
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List style={{ padding: "0px 10px" }}>
                  <List.Item style={{ padding: "10px 0px" }}>
                    13. CIREBON
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    14. DENPASAR
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    15. JAKARTA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    16. JAMBI
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    17. JAYAPURA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    18. JEMBER
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    19. KENDARI
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    20. KETAPANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    21. KUPANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    22. LAMPUNG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    23. MAKASSAR
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    24. MALANG
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List style={{ padding: "0px 10px" }}>
                  <List.Item style={{ padding: "10px 0px" }}>
                    25. MANADO
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    26. MANOKWARI
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    27. MATARAM
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    28. MAUMERE
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    29. MEDAN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    30. MELAK
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    31. MERAUKE
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    32. NABIRE
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    33. PADANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    34. PADANG SIDEMPUAN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    35. PALANGKARAYA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    36. PALEMBANG
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List style={{ padding: "0px 10px" }}>
                  <List.Item style={{ padding: "10px 0px" }}>
                    37. PALU
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    38. PANGKAL PINANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    39. PANGKALAN BUN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    40. PEKANBARU
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    41. PONTIANAK
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    42. RUTENG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    43. SAMARINDA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    44. SAMPIT
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    45. SEMARANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    46. SINGKAWANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    47. SINTANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    48. SOLO
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List style={{ padding: "0px 10px" }}>
                  <List.Item style={{ padding: "10px 0px" }}>
                    49. SORONG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    50. SUKABUMI
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    51. SUMBA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    52. SUMBAWA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    53. SURABAYA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    54. TANJUNG PINANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    55. TARAKAN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    56. TASIKMALAYA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    57. TEGAL
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    58. TERNATE
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    59. TIMIKA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    60. YOGYAKARTA
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </Responsive>
          <Responsive minWidth={300} maxWidth={755}>
            <Grid
              stackable
              columns={5}
              style={{ color: "#FFF", padding: "1em 0em", marginLeft: "33%" }}
            >
              <Grid.Column>
                <List style={{ padding: "0px 10px" }}>
                  <List.Item style={{ padding: "10px 0px" }}>
                    1. AMBON
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    2. BALIKPAPAN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    3. BANDUNG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    4. BANJARMASIN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    5. BATAM
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    6. BATULICIN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    7. BENGKULU
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>8. BIMA</List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    9. BOGOR
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    10. BUKITTINGGI
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    11. BUNTOK
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    12. CIKAMPEK
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List style={{ padding: "0px 10px" }}>
                  <List.Item style={{ padding: "10px 0px" }}>
                    13. CIREBON
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    14. DENPASAR
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    15. JAKARTA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    16. JAMBI
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    17. JAYAPURA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    18. JEMBER
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    19. KENDARI
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    20. KETAPANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    21. KUPANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    22. LAMPUNG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    23. MAKASSAR
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    24. MALANG
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List style={{ padding: "0px 10px" }}>
                  <List.Item style={{ padding: "10px 0px" }}>
                    25. MANADO
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    26. MANOKWARI
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    27. MATARAM
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    28. MAUMERE
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    29. MEDAN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    30. MELAK
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    31. MERAUKE
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    32. NABIRE
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    33. PADANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    34. PADANG SIDEMPUAN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    35. PALANGKARAYA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    36. PALEMBANG
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List style={{ padding: "0px 10px" }}>
                  <List.Item style={{ padding: "10px 0px" }}>
                    37. PALU
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    38. PANGKAL PINANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    39. PANGKALAN BUN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    40. PEKANBARU
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    41. PONTIANAK
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    42. RUTENG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    43. SAMARINDA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    44. SAMPIT
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    45. SEMARANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    46. SINGKAWANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    47. SINTANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    48. SOLO
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List style={{ padding: "0px 10px" }}>
                  <List.Item style={{ padding: "10px 0px" }}>
                    49. SORONG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    50. SUKABUMI
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    51. SUMBA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    52. SUMBAWA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    53. SURABAYA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    54. TANJUNG PINANG
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    55. TARAKAN
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    56. TASIKMALAYA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    57. TEGAL
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    58. TERNATE
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    59. TIMIKA
                  </List.Item>
                  <List.Item style={{ padding: "10px 0px" }}>
                    60. YOGYAKARTA
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </Responsive>
        </Container>
      </div>
      <Responsive minWidth={755} maxWidth={2559}>
        <Grid.Column
          width={16}
          style={{ background: "#FF8C00", height: 83 }}
        ></Grid.Column>
      </Responsive>
      <Responsive minWidth={300} maxWidth={755}>
        <Grid.Column
          width={16}
          style={{ background: "#FF8C00", height: 491 }}
        ></Grid.Column>
      </Responsive>
      <Responsive minWidth={200} maxWidth={300}>
        <Grid.Column
          width={16}
          style={{ background: "#FF8C00", height: 342 }}
        ></Grid.Column>
      </Responsive>
    </>
  );
}
