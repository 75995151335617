import React, { useState } from "react";
import {
  // Button,
  Card,
  // Container,
  // Divider,
  Grid,
  // Icon,
  Image,
  // Label,
  Reveal
  // Header,
  // Segment
} from "semantic-ui-react";
import Fade from "react-reveal/Fade";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import AllM from "../Images/Management/AllM.jpg";
import ContactUs from "../Images/Management/ContactUs.jpg";
import ISO9001 from "../Images/Management/ISO-BV.jpg";
import ISO45001 from "../Images/Management/ISO-BSI.jpg";
// import styleCM from "../Styles/styleCM.css"

export default function Management() {
  const [cardManag1, setCardManag1] = useState();
  const [cardManag2, setCardManag2] = useState();
  const [cardManag3, setCardManag3] = useState();

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -1000) {
      setCardManag1(
        <Fade right>
          <Card style={imageFrame}>
            <Reveal animated="fade" style={{ margin: "auto" }}>
              <Reveal.Content visible>
                <Image src={AllM} size="large" />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Image src={AllM} size="large" />
              </Reveal.Content>
            </Reveal>
            <Card.Content>
              <Card.Header>About Us</Card.Header>
              <Card.Meta>
                <span className="date">
                  Business experience more than 20 years
                </span>
              </Card.Meta>
              <Card.Description>
                Meet HIL - Best Partner and Solution for your Business.
                <br />
              </Card.Description>
            </Card.Content>
          </Card>
        </Fade>
      );
    }

    if (currPos.y < -1000) {
      setCardManag2(
        <Fade right>
          <Card style={imageFrame}>
            <Reveal animated="fade" style={{ margin: "auto" }}>
              <Reveal.Content visible>
                <Image src={ContactUs} size="large" />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Image src={ContactUs} size="large" />
              </Reveal.Content>
            </Reveal>
            <Card.Content>
              <Card.Header>Contact Us</Card.Header>
              <Card.Meta>
                <span className="date">Central in Jakarta</span>
              </Card.Meta>
              <Card.Description>
                Our Team is ready to answer any question that you may have the
                further information.
              </Card.Description>
            </Card.Content>
          </Card>
        </Fade>
      );
    }

    if (currPos.y < -1000) {
      setCardManag3(
        <Fade right>
          <Card style={imageFrame}>
            <Reveal animated="fade" style={{ margin: "auto" }}>
              <Reveal.Content visible>
                <Image src={ISO45001} size="large" />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Image src={ISO9001} size="large" />
              </Reveal.Content>
            </Reveal>
            <Card.Content>
              <Card.Header>Accreditation</Card.Header>
              <Card.Meta>
                <span className="date">ISO 9001 (BV) and ISO 45001 (BSI)</span>
              </Card.Meta>
              <Card.Description>
                Customized service based on your need to ease your inventory
                process prepared.
              </Card.Description>
            </Card.Content>
          </Card>
        </Fade>
      );
    }
  });

  return (
    // <Container style={{ padding: "2em 0em", backgroundColor: "white" }}>
    <Grid
      columns={3}
      stackable
      style={{ backgroundColor: "white", padding: "1em 0em" }}
    >
      <Grid.Column style={{ marginTop: 20, marginBottom: 30 }}>
        {cardManag1}
      </Grid.Column>
      <Grid.Column style={{ marginTop: 20, marginBottom: 30 }}>
        {cardManag3}
      </Grid.Column>
      <Grid.Column style={{ marginTop: 20, marginBottom: 30 }}>
        {cardManag2}
      </Grid.Column>
      {/* <Grid.Column
        width={8}
        style={{ marginTop: 20, marginBottom: 30, paddingRight: "9%" }}
      >
        <Reveal animated="fade" style={{ margin: "auto", width: "80%" }}>
          <Reveal.Content visible>
            <Image
              src="https://react.semantic-ui.com/images/wireframe/square-image.png"
              size="large"
              style={borderImage}
            />
          </Reveal.Content>
          <Reveal.Content hidden>
            <Image
              src="https://react.semantic-ui.com/images/avatar/large/ade.jpg"
              size="large"
              style={borderImage}
            />
          </Reveal.Content>
        </Reveal>
        <Label
          as="div"
          style={{
            position: "relative",
            width: "80%",
            height: "25%",
            zIndex: 9,
            bottom: 50
          }}
        >
          <Header
            style={{ fontFamily: "gadugi", marginTop: 8, marginBottom: 2 }}
            as="h3"
          >
            Contact Us
          </Header>
          <p style={{ color: "#bdbdbd", fontFamily: "gadugi", fontSize: 12 }}>
            Central in Jakarta
          </p>
          <p style={{ color: "#757575", fontFamily: "gadugi", fontSize: 14 }}>
            Our Team is ready to answer any question that you may have the
            further information.
          </p>
        </Label>
      </Grid.Column> */}
    </Grid>
    // </Container>
  );
}

const imageFrame = {
  backgroundSize: "cover",
  backgroundPosition: "center center",
  boxShadow: "0px 10px 30px -5px rgba(0, 0, 0, 0.3)",
  transition: "box-shadow 0.5s",
  willChange: "transform",
  margin: "auto",
  width: "65%"
};

// const borderImage = {
//   backgroundSize: "cover",
//   borderRadius: "5px",
//   backgroundPosition: "center center",
//   boxShadow: "0px 10px 30px -5px rgba(0, 0, 0, 0.3)",
//   transition: "box-shadow 0.5s",
//   willChange: "transform"
//   // border: "15px solid white"
// };
