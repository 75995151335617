import React, { useState, useEffect } from "react";
import {
  Button,
  // Card,
  Container,
  Divider,
  Grid,
  Icon,
  Image,
  Reveal,
  Responsive,
  Header,
  // Segment
} from "semantic-ui-react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
// import Flip from "react-reveal/Flip";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
// import { useSpring, animated } from "react-spring";

import OS1 from "../Images/OurServices/OS1.jpg";
import OS2 from "../Images/OurServices/OS2.jpg";
import OS3 from "../Images/OurServices/OS3.jpg";
import OS4 from "../Images/OurServices/OS4.jpg";
import ImgHover from "../Images/OurServices/ImgHover.png";
// import Circular from "../Images/OurServices/circular-white.png";
import CC2 from "../Images/OurServices/CC2.png";
import Repair2 from "../Images/OurServices/Repair2.png";

export default function OurServices() {
  const [headOS, setHeadOS] = useState();
  const [headOSM, setHeadOSM] = useState();
  const [ourServ1, setOurServ1] = useState();
  const [ourServ1M, setOurServ1M] = useState();
  const [ourServ2, setOurServ2] = useState();
  const [ourServ2M, setOurServ2M] = useState();
  const [ourServ3, setOurServ3] = useState();
  const [ourServ3M, setOurServ3M] = useState();
  const [ourServ4, setOurServ4] = useState();
  const [ourServ4M, setOurServ4M] = useState();
  const [btnViewServices, setBtnViewServices] = useState();

  useEffect(() => {
    setHeadOSM(<Fade top>Our Services</Fade>);
    setOurServ1M(
      <Fade bottom>
        <Reveal animated="fade" style={{ margin: "auto", width: "82%" }}>
          <Reveal.Content visible></Reveal.Content>
          <Reveal.Content hidden>
            <Image src={OS1} size="large" style={styles.imageFrame} />
            <Responsive>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  bottom: -145,
                }}
              >
                <Header as="h2" icon style={{ color: "#FFF" }}>
                  <Icon name="settings" />
                  Suply Chain Management
                </Header>
              </div>
            </Responsive>
          </Reveal.Content>
        </Reveal>
      </Fade>
    );
  }, []);
  useScrollPosition(({ prevPos, currPos }) => {
    // Mobile ===============================================
    if (currPos.y < -100) {
      setOurServ2M(
        <Fade bottom>
          <Reveal animated="fade" style={{ margin: "auto", width: "82%" }}>
            <Reveal.Content visible></Reveal.Content>
            <Reveal.Content hidden>
              <Image src={OS2} size="large" style={styles.imageFrame} />
              <Responsive>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bottom: -145,
                  }}
                >
                  <Header as="h2" icon style={{ color: "#FFF" }}>
                    <Icon name="warehouse" />
                    Warehouse and Distribution
                  </Header>
                </div>
              </Responsive>
            </Reveal.Content>
          </Reveal>
        </Fade>
      );
    }

    if (currPos.y < -300) {
      setOurServ3M(
        <Fade bottom>
          <Reveal animated="fade" style={{ margin: "auto", width: "82%" }}>
            <Reveal.Content visible></Reveal.Content>
            <Reveal.Content hidden>
              <Image src={OS3} size="large" style={styles.imageFrame} />
              <Responsive>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bottom: -145,
                  }}
                >
                  <Image
                    src={CC2}
                    style={{ width: 110, margin: "auto", top: -25 }}
                  />
                  <Header as="h2" style={{ color: "#FFF", marginTop: -30 }}>
                    Control Tower
                  </Header>
                </div>
              </Responsive>
            </Reveal.Content>
          </Reveal>
        </Fade>
      );
    }

    if (currPos.y < -400) {
      setOurServ4M(
        <Fade bottom>
          <Reveal animated="fade" style={{ margin: "auto", width: "82%" }}>
            <Reveal.Content visible></Reveal.Content>
            <Reveal.Content hidden>
              <Image src={OS4} size="large" style={styles.imageFrame} />
              <Responsive>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bottom: -145,
                  }}
                >
                  <Image
                    src={Repair2}
                    style={{ width: 110, margin: "auto", top: -18 }}
                  />
                  <Header as="h2" style={{ color: "#FFF", marginTop: -30 }}>
                    Testbad or <br /> Prescreening
                  </Header>
                </div>
              </Responsive>
            </Reveal.Content>
          </Reveal>
        </Fade>
      );
    }

    // Web ==================================================
    if (currPos.y < -60) {
      setHeadOS(<Fade top>Our Services</Fade>);
    }

    if (currPos.y < -60) {
      setOurServ1(
        <Fade bottom>
          <Reveal animated="fade" style={{ margin: "auto", width: "82%" }}>
            <Reveal.Content visible>
              <Responsive>
                <Image src={ImgHover} size="large" style={styles.imgHover} />
              </Responsive>
            </Reveal.Content>
            <Reveal.Content hidden>
              <Image src={OS1} size="large" style={styles.imageFrame} />
              <Responsive>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bottom: -268,
                  }}
                >
                  <Header as="h2" icon style={{ color: "#FFF" }}>
                    <Icon name="settings" />
                    Suply Chain Management
                  </Header>
                </div>
              </Responsive>
            </Reveal.Content>
          </Reveal>
        </Fade>
      );
    }

    if (currPos.y < -60) {
      setOurServ2(
        <Fade bottom>
          <Reveal animated="fade" style={{ margin: "auto", width: "82%" }}>
            <Reveal.Content visible>
              <Responsive>
                <Image src={ImgHover} size="large" style={styles.imgHover} />
              </Responsive>
            </Reveal.Content>
            <Reveal.Content hidden>
              <Image src={OS2} size="large" style={styles.imageFrame} />
              <Responsive>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bottom: -268,
                  }}
                >
                  <Header as="h2" icon style={{ color: "#FFF" }}>
                    <Icon name="warehouse" />
                    Warehouse and Distribution
                  </Header>
                </div>
              </Responsive>
            </Reveal.Content>
          </Reveal>
        </Fade>
      );
    }

    if (currPos.y < -600) {
      setOurServ3(
        <Fade bottom>
          <Reveal animated="fade" style={{ margin: "auto", width: "82%" }}>
            <Reveal.Content visible>
              <Responsive minWidth={755} maxWidth={2559}>
                <Image src={ImgHover} size="large" style={styles.imgHover} />
              </Responsive>
              <Responsive minWidth={300} maxWidth={755}></Responsive>
            </Reveal.Content>
            <Reveal.Content hidden>
              <Image src={OS3} size="large" style={styles.imageFrame} />
              <Responsive minWidth={755} maxWidth={2559}>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bottom: -268,
                  }}
                >
                  <Image
                    src={CC2}
                    style={{ width: 110, margin: "auto", top: -25 }}
                  />
                  <Header as="h2" style={{ color: "#FFF", marginTop: -30 }}>
                    Control Tower
                  </Header>
                </div>
              </Responsive>
              <Responsive minWidth={300} maxWidth={755}>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bottom: -145,
                  }}
                >
                  <Image
                    src={CC2}
                    style={{ width: 110, margin: "auto", top: -25 }}
                  />
                  <Header as="h2" style={{ color: "#FFF", marginTop: -30 }}>
                    Control Tower
                  </Header>
                </div>
              </Responsive>
            </Reveal.Content>
          </Reveal>
        </Fade>
      );
    }

    if (currPos.y < -600) {
      setOurServ4(
        <Fade bottom>
          <Reveal animated="fade" style={{ margin: "auto", width: "82%" }}>
            <Reveal.Content visible>
              <Responsive minWidth={755} maxWidth={2559}>
                <Image src={ImgHover} size="large" style={styles.imgHover} />
              </Responsive>
              <Responsive minWidth={300} maxWidth={755}></Responsive>
            </Reveal.Content>
            <Reveal.Content hidden>
              <Image src={OS4} size="large" style={styles.imageFrame} />
              <Responsive minWidth={755} maxWidth={2559}>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bottom: -268,
                  }}
                >
                  <Image
                    src={Repair2}
                    style={{ width: 110, margin: "auto", top: -18 }}
                  />
                  <Header as="h2" style={{ color: "#FFF", marginTop: -30 }}>
                    Testbad or Prescreening
                  </Header>
                </div>
              </Responsive>
              <Responsive minWidth={300} maxWidth={755}>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bottom: -145,
                  }}
                >
                  <Image
                    src={Repair2}
                    style={{ width: 110, margin: "auto", top: -18 }}
                  />
                  <Header as="h2" style={{ color: "#FFF", marginTop: -30 }}>
                    Testbad or Prescreening
                  </Header>
                </div>
              </Responsive>
            </Reveal.Content>
          </Reveal>
        </Fade>
      );
    }

    if (currPos.y < -900) {
      setBtnViewServices(
        <Fade bottom>
          <Button
            as={Link}
            size="big"
            to="/services"
            style={{ backgroundColor: "#FF8C00", color: "#FFF" }}
          >
            View More
          </Button>
        </Fade>
      );
    }
  });

  // console.log(scrollY);
  return (
    <Container style={{ padding: "2em 0em" }}>
      <Grid columns={1} stackable>
        <Grid.Column width={16}>
          <Responsive minWidth={755} maxWidth={2559}>
            <Divider
              as="h1"
              className="header"
              horizontal
              style={{
                color: "#FF8C00",
                fontFamily: "gadugi",
                margin: "1em 0em",
                textTransform: "uppercase",
              }}
            >
              {headOS}
            </Divider>
          </Responsive>
          <Responsive minWidth={300} maxWidth={755}>
            <Divider
              as="h1"
              className="header"
              horizontal
              style={{
                color: "#FF8C00",
                fontFamily: "gadugi",
                textTransform: "uppercase",
              }}
            >
              {headOSM}
            </Divider>
          </Responsive>
        </Grid.Column>

        <Grid.Column width={8}>
          <Responsive minWidth={755} maxWidth={2559}>
            {ourServ1}
          </Responsive>
          <Responsive minWidth={300} maxWidth={755}>
            {ourServ1M}
          </Responsive>
        </Grid.Column>

        <Grid.Column width={8}>
          <Responsive minWidth={755} maxWidth={2559}>
            {ourServ2}
          </Responsive>
          <Responsive minWidth={300} maxWidth={755}>
            {ourServ2M}
          </Responsive>
        </Grid.Column>

        <Grid.Column width={16} style={{ margin: "1em" }}></Grid.Column>

        <Grid.Column width={8}>
          <Responsive minWidth={755} maxWidth={2559}>
            {ourServ3}
          </Responsive>
          <Responsive minWidth={300} maxWidth={755}>
            {ourServ3M}
          </Responsive>
        </Grid.Column>

        <Grid.Column width={8}>
          <Responsive minWidth={755} maxWidth={2559}>
            {ourServ4}
          </Responsive>
          <Responsive minWidth={300} maxWidth={755}>
            {ourServ4M}
          </Responsive>
        </Grid.Column>

        <Grid.Column width={16} style={{ margin: "1em" }}>
          <Divider
            as="h1"
            className="header"
            horizontal
            style={{
              fontFamily: "gadugi",
              margin: "1em 0em",
              textTransform: "uppercase",
            }}
          >
            {btnViewServices}
          </Divider>
        </Grid.Column>
      </Grid>
    </Container>
  );
}

let styles = {
  imageFrame: {
    background: "grey",
    borderRadius: "5px",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    boxShadow: "0px 10px 30px -5px rgba(0, 0, 0, 0.3)",
    transition: "box-shadow 0.5s",
    willChange: "transform",
    backgroundColor: "transparent",
  },

  imgHover: {
    background: "grey",
    borderRadius: "5px",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    boxShadow: "0px 10px 30px -5px rgba(0, 0, 0, 0.3)",
    transition: "box-shadow 0.5s",
    willChange: "transform",
    backgroundColor: "transparent",
    opacity: 0.5,
  },
};
