import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";

import { ServicesOne, ServicesTwo } from "./";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Carousel
      emulateTouch
      // dynamicHeight
      // autoPlay
      className="presentation-mode"
      showThumbs={false}
      showStatus={false}
    >
      <div className="my-slide content" style={{}}>
        <ServicesOne />
      </div>
      <div className="my-slide content">
        <ServicesTwo />
      </div>
    </Carousel>
  );
};

export default Services;
