import React from "react";
import {
  Container,
  // Divider,
  // Header,
  Grid,
  // Icon,
  Image,
  // List,
  Responsive,
  // Segment,
  // Message
} from "semantic-ui-react";

import CI1 from "../Images/Customer/1nokia.png";
import CI2 from "../Images/Customer/2alcatel.png";
import CI3 from "../Images/Customer/3rnets.png";

import CI4 from "../Images/Customer/4adva.png";
import CI5 from "../Images/Customer/5hwi2.png";
import CI6 from "../Images/Customer/6tsel.png";
import CI7 from "../Images/Customer/7isat.png";

import CI8 from "../Images/Customer/8xl2.png";
import CI9 from "../Images/Customer/9three2.png";
import CI10 from "../Images/Customer/91smart.png";
import CI11 from "../Images/Customer/92net1.png";
import CI12 from "../Images/Customer/93inovasi2.png";

import CI13 from "../Images/Customer/94datacomm.png";
import CI14 from "../Images/Customer/95moratelindo.png";
import CI15 from "../Images/Customer/96balitower.png";
import CI16 from "../Images/Customer/97lintas.png";

export default function CustomerImage() {
  return (
    <>
      <Container style={{ padding: "0em 0em" }}>
        <Responsive minWidth={768} maxWidth={2559}>
          <Grid stackable style={{ textAlign: "left", padding: "1em 0em" }}>
            <Grid.Row columns={3}>
              <Grid.Column>
                <Image src={CI1} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI2} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI3} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
              <Grid.Column>
                <Image src={CI4} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI5} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI6} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI7} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column width={2}>
                <Image src={CI8} />
              </Grid.Column>
              <Grid.Column width={2}>
                <Image src={CI9} />
              </Grid.Column>
              <Grid.Column width={5}>
                <Image src={CI10} style={{ paddingTop: "2%" }} />
              </Grid.Column>
              <Grid.Column width={4}>
                <Image src={CI11} style={{ paddingTop: "6%" }} />
              </Grid.Column>
              <Grid.Column width={2}>
                <Image src={CI12} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
              <Grid.Column>
                <Image src={CI13} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI14} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI15} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI16} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
        <Responsive minWidth={100} maxWidth={767}>
          <Grid stackable style={{ textAlign: "left", padding: "1em 0em" }}>
            <Grid.Row columns={3}>
              <Grid.Column>
                <Image src={CI1} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI2} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI3} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
              <Grid.Column>
                <Image src={CI4} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI5} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI6} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI7} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column width={2}>
                <Image src={CI8} style={{ width: "33%", margin: "auto" }} />
              </Grid.Column>
              <Grid.Column width={2}>
                <Image src={CI9} style={{ width: "33%", margin: "auto" }} />
              </Grid.Column>
              <Grid.Column width={5}>
                <Image src={CI10} style={{ paddingTop: "2%" }} />
              </Grid.Column>
              <Grid.Column width={4}>
                <Image src={CI11} style={{ paddingTop: "6%" }} />
              </Grid.Column>
              <Grid.Column width={2}>
                <Image src={CI12} style={{ width: "33%", margin: "auto" }} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
              <Grid.Column>
                <Image src={CI13} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI14} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI15} />
              </Grid.Column>
              <Grid.Column>
                <Image src={CI16} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
      </Container>
    </>
  );
}
