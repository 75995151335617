import PropTypes from "prop-types";
import React, { Component, useState } from "react";
import { Route, Switch, Link } from "react-router-dom";
import {
  Button,
  Container,
  Header,
  Icon,
  Image,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";

import { Home, About, Services, Contact } from "./";
import { Footer, LogoNav } from "../Modules";
import imgHIL2 from "../Images/Logo/HIL2.png";

import "../Styles/styleMainPage.css";

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as="h1"
      content="Imagine-a-Company"
      inverted
      style={{
        fontSize: mobile ? "2em" : "4em",
        fontWeight: "normal",
        marginBottom: 0,
        marginTop: mobile ? "1.5em" : "3em",
      }}
    />
    <Header
      as="h2"
      content="Do whatever you want when you want to."
      inverted
      style={{
        fontSize: mobile ? "1.5em" : "1.7em",
        fontWeight: "normal",
        marginTop: mobile ? "0.5em" : "1.5em",
      }}
    />
    <Button primary size="huge">
      Get Started
      <Icon name="right arrow" />
    </Button>
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
// class DesktopContainer extends Component {
const DesktopContainer = (props) => {
  const { children } = props;
  const [fixed, setFixed] = useState(false);
  const [activeItem, setActiveItem] = useState("home");
  ////

  // state = {};

  // hideFixedMenu = () => this.setState({ fixed: false });
  // showFixedMenu = () => this.setState({ fixed: true });

  // render() {
  //   const { children } = this.props;
  //   const { fixed } = this.state;

  return (
    <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
      <Visibility
        once={false}
        // onBottomPassed={() => setFixed(true)}
        onBottomPassedReverse={() => setFixed(false)}
      >
        <Segment
          inverted
          textAlign="center"
          style={{
            height: 43,
            padding: "0em 0em 0em 0em",
            backgroundColor: "#FFF",
          }}
          vertical
        >
          <Menu
            fixed="top"
            inverted={!fixed}
            pointing={!fixed}
            secondary={!fixed}
            size="large"
            style={{
              borderColor: "#FF8C00",
              borderWidth: 0,
              backgroundColor: "#FF8C00",
              height: 43,
            }}
          >
            <Container>
              <LogoNav scroll="55" position="left" />
              <Menu.Menu position="right" style={{ fontFamily: "gadugi" }}>
                <Link to="/home" style={{ color: "white" }}>
                  <Menu.Item
                    as="div"
                    active={activeItem === "home"}
                    onClick={() => setActiveItem("home")}
                    style={{ color: "#FFF" }}
                  >
                    Home
                  </Menu.Item>
                </Link>
                <Link to="/about" style={{ color: "white" }}>
                  <Menu.Item
                    as="div"
                    active={activeItem === "about"}
                    onClick={() => setActiveItem("about")}
                    style={{ color: "#FFF" }}
                  >
                    About
                  </Menu.Item>
                </Link>
                <Link to="/services" style={{ color: "white" }}>
                  <Menu.Item
                    as="div"
                    active={activeItem === "services"}
                    onClick={() => setActiveItem("services")}
                    style={{ color: "#FFF" }}
                  >
                    Services
                  </Menu.Item>
                </Link>
                <Link to="/contact" style={{ color: "white" }}>
                  <Menu.Item
                    as="div"
                    active={activeItem === "contact"}
                    onClick={() => setActiveItem("contact")}
                    style={{ color: "#FFF" }}
                  >
                    Contact
                  </Menu.Item>
                </Link>
              </Menu.Menu>
            </Container>
          </Menu>
          {/* <HomepageHeading /> */}
        </Segment>
      </Visibility>

      {children}
    </Responsive>
  );
  // }
};

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

const MobileContainer = (props) => {
  const { children } = props;
  const [activeItem, setActiveItem] = useState("home");
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const handleSidebarHide = () => {
    setSidebarOpened(false);
  };

  const handleToggle = () => {
    setSidebarOpened(true);
  };

  const handleMobileMenu = (e) => {
    setActiveItem(e);
    handleSidebarHide();
  };

  return (
    <Responsive
      as={Sidebar.Pushable}
      getWidth={getWidth}
      maxWidth={Responsive.onlyMobile.maxWidth}
    >
      <Sidebar
        as={Menu}
        animation="push"
        direction="right"
        inverted
        onHide={handleSidebarHide}
        vertical
        visible={sidebarOpened}
      >
        <Link to="/home">
          <Menu.Item
            as="div"
            name="home"
            active={activeItem === "home"}
            onClick={() => handleMobileMenu("home")}
          >
            Home
          </Menu.Item>
        </Link>
        <Link to="/about">
          <Menu.Item
            as="div"
            name="about"
            active={activeItem === "about"}
            onClick={() => handleMobileMenu("about")}
          >
            About
          </Menu.Item>
        </Link>
        <Link to="/services">
          <Menu.Item
            as="div"
            name="services"
            active={activeItem === "services"}
            onClick={() => handleMobileMenu("services")}
          >
            Services
          </Menu.Item>
        </Link>
        <Link to="/contact">
          <Menu.Item
            as="div"
            name="contact"
            active={activeItem === "contact"}
            onClick={() => handleMobileMenu("contact")}
          >
            Contact
          </Menu.Item>
        </Link>
      </Sidebar>

      <Sidebar.Pusher dimmed={sidebarOpened}>
        <Segment
          inverted
          textAlign="center"
          style={{
            minHeight: 0,
            padding: "1em 0em",
            backgroundColor: "#FF8C00",
          }}
          vertical
        >
          <Container>
            <Menu
              inverted
              pointing
              secondary
              size="large"
              style={{ borderColor: "#FF8C00" }}
            >
              <Menu.Item style={{ padding: 0 }}>
                <Image
                  src={imgHIL2}
                  style={{ width: "45px", height: "45px", margin: "auto" }}
                />
              </Menu.Item>
              <Menu.Item position="right" onClick={handleToggle}>
                <Icon name="sidebar" />
              </Menu.Item>
            </Menu>
          </Container>
          {/* <HomepageHeading mobile /> */}
        </Segment>

        {children}
      </Sidebar.Pusher>
    </Responsive>
  );
};

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const MainPage = () => {
  return (
    <ResponsiveContainer>
      <Switch>
        <Route restricted={false} path="/" exact component={Home} />
        <Route restricted={false} path="/home" exact component={Home} />
        <Route restricted={false} path="/about" exact component={About} />
        <Route restricted={false} path="/services" exact component={Services} />
        <Route restricted={false} path="/contact" exact component={Contact} />
      </Switch>
      <Footer />
    </ResponsiveContainer>
  );
};
export default MainPage;
