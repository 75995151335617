import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// import { Responsive, Image } from "semantic-ui-react";
// import styleAbout from "../Styles/styleAbout.css";

import "../Styles/styleCarousel.css";

import { AboutOne, AboutTwo } from "./";
// import SH1 from "../Images/SlideHeader/SH1.png";
// import SH2 from "../Images/SlideHeader/SH2.png";
// import SH3 from "../Images/SlideHeader/SH3.png";

const About = () => {
  return (
    <Carousel
      emulateTouch
      // dynamicHeight
      // autoPlay
      className="presentation-mode"
      showThumbs={false}
      showStatus={false}
    >
      <div className="my-slide content" style={{}}>
        <AboutOne />
      </div>
      <div className="my-slide content">
        <AboutTwo />
      </div>
    </Carousel>
  );
};

export default About;
