import React, { useEffect } from "react";
// import OrgChart from "@capricasoftware/react-org-chart";
import {
  // Card,
  Container,
  Divider,
  Image,
  // Header,
  // Label,
  Responsive
} from "semantic-ui-react";

import { OrganizationChart, OrganizationCard, CustomerImage } from "../Modules";

import OC from "../Images/StructuralOrg/OurCustomer.jpg";
import "../Styles/styleAboutTwo.css";

export default function AboutTwo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ background: "white" }}>
      <Responsive minWidth={755} maxWidth={2559}>
        <Container>
          <Divider
            as="h1"
            className="header"
            horizontal
            style={{
              color: "#FF8C00",
              fontFamily: "gadugi",
              padding: "1.2em 0em 0.7em 0em",
              textTransform: "uppercase",
              margin: 0
            }}
          >
            Management and Stakeholders
          </Divider>
        </Container>
        <OrganizationChart />
      </Responsive>
      <Responsive minWidth={300} maxWidth={755}>
        <Container>
          <Divider
            as="h2"
            className="header"
            horizontal
            style={{
              color: "#FF8C00",
              fontFamily: "gadugi",
              padding: "1.2em 0em 0.7em 0em",
              textTransform: "uppercase",
              margin: 0
            }}
          >
            Management <br /> and <br /> Stakeholders
          </Divider>
        </Container>
        <OrganizationCard />
      </Responsive>
      <div style={imageFrame}>
        <Image src={OC} />
      </div>
      <Container>
        <Divider
          as="h1"
          className="header"
          horizontal
          style={{
            color: "#FF8C00",
            fontFamily: "gadugi",
            padding: "2em 0em 0.5em 0em",
            textTransform: "uppercase",
            margin: 0
          }}
        >
          Our Customer
        </Divider>
      </Container>
      <CustomerImage />
    </div>
  );
}

const imageFrame = {
  boxShadow: "0px 10px 30px -10px rgba(0, 0, 0, 0.3)"
};
