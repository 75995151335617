import React from "react";
import {
  // Card,
  Container,
  Divider,
  Header,
  Image,
  Label,
  // Rail,
  Rating,
  Responsive,
  Segment
} from "semantic-ui-react";

import BG from "../Images/Services/BGtransFull.png";

import SV1 from "../Images/Services/SV1.jpg";
import SV2 from "../Images/Services/SV2.jpg";
import SV3 from "../Images/Services/SV3.jpg";

// import SV4 from "../Images/Services/SV4.png";
// import SV5 from "../Images/Services/SV5.png";
// import SV6 from "../Images/Services/SV6.png";
// import { OrganizationChart, OrganizationCard, CustomerImage } from "../Modules";

export default function ServicesOne() {
  return (
    <>
      <Responsive minWidth={755} maxWidth={2559}>
        <div
          style={{
            background: "#eee",
            backgroundImage: `url(${BG})`,
            backgroundRepeat: "no-repeat",
            paddingBottom: 50
          }}
        >
          <Container style={{ padding: "1em 0em" }}>
            <Divider
              as="h1"
              className="header"
              horizontal
              style={{
                color: "#FF8C00",
                fontFamily: "gadugi",
                padding: "1em 0em 1.5em 0em",
                textTransform: "uppercase",
                margin: 0
              }}
            >
              Our Services
            </Divider>

            <Segment
              style={{
                margin: "0em 5em 2em 0em",
                minHeight: 340,
                // textAlign: "justify"
              }}
              raised
            >
              <Header as="h2" floated="left" style={{ marginTop: 6 }}>
                Call Center
              </Header>
              {/* <Label
            color="orange"
            ribbon="right"
            size="big"
            style={{ margin: "auto", left: "90.5%" }}
          >
            <Rating icon="star" defaultRating={5} maxRating={5} disabled />
          </Label> */}
              {/* </div> */}

              <Divider clearing />
              <Image
                src={SV1}
                size="medium"
                floated="left"
                style={{ borderRadius: 5 }}
              />
              <p style={{ fontSize: 22, textAlign: "justify" }}>
                Responsive communications and state-of-art administration system
                are the strength of our frontliners, to answer customer
                sexpectation for timely and satisfactory attention. Our Call
                Center is available to serve 24 hours and equipped with
                integrated system.
              </p>
            </Segment>
            <Segment
              style={{
                margin: "0em 0em 2em 5em",
                minHeight: 340,
                // textAlign: "justify"
              }}
            >
              <Header as="h2" floated="left" style={{ marginTop: 6 }}>
                Supply Chain Management
              </Header>
              {/* <Label
            color="orange"
            ribbon="right"
            size="big"
            style={{ margin: "auto", left: "74.5%" }}
          >
            <Rating icon="star" defaultRating={5} maxRating={5} disabled />
          </Label> */}
              <Divider clearing />
              <Image
                src={SV2}
                size="medium"
                floated="right"
                style={{ borderRadius: 5 }}
              />
              <p style={{ fontSize: 22, textAlign: "justify" }}>
                Our Supply Chain Management utilizes with the latest technology
                and system to simplify your needs in storing, managing, moving
                and inventorying your stock. We cultivate a web-based integrated
                system to serve all process such as ordering, tracing and all
                reporting. Through our competent logistics networks. We can
                ensure rapid inbound and outbound supply of in.
              </p>
            </Segment>
            <Segment
              style={{
                margin: "0em 5em 2em 0em",
                minHeight: 340,
                // textAlign: "justify"
              }}
              raised
            >
              <Header as="h2" floated="left" style={{ marginTop: 6 }}>
                IT for Inventory and Logistics Product
              </Header>
              {/* <Label
            color="orange"
            ribbon="right"
            size="big"
            style={{ margin: "auto", left: "63.2%" }}
          >
            <Rating icon="star" defaultRating={5} maxRating={5} disabled />
          </Label> */}
              {/* </div> */}

              <Divider clearing />
              <Image
                src={SV3}
                size="medium"
                floated="left"
                style={{ borderRadius: 5 }}
              />
              <p style={{ fontSize: 22, textAlign: "justify" }}>
                The creation of specialized inventory system, particulary in
                field of inventory and logistics. Using supply chain management
                close loop pattern, designing the trace to start from inbound,
                outbound, repair to replacement.
              </p>
            </Segment>
          </Container>
        </div>
      </Responsive>
      <Responsive minWidth={300} maxWidth={755}>
        <div
          style={{
            background: "#eee",
            backgroundImage: `url(${BG})`,
            backgroundRepeat: "no-repeat",
            paddingBottom: 327
          }}
        >
          <Container style={{ padding: "1em 0em" }}>
            <Divider
              as="h1"
              className="header"
              horizontal
              style={{
                color: "#FF8C00",
                fontFamily: "gadugi",
                padding: "1em 0em 1.5em 0em",
                textTransform: "uppercase",
                margin: 0
              }}
            >
              Our Services
            </Divider>

            <Segment
              style={{
                margin: "0em 5em 2em 0em",
                minHeight: 340,
                // textAlign: "justify"
              }}
              raised
            >
              <Header as="h2" floated="left" style={{ marginTop: 6 }}>
                Call Center
              </Header>
              {/* <Label
            color="orange"
            ribbon="right"
            size="big"
            style={{ margin: "auto", left: "90.5%" }}
          >
            <Rating icon="star" defaultRating={5} maxRating={5} disabled />
          </Label> */}
              {/* </div> */}

              <Divider clearing />
              <Image
                src={SV1}
                size="medium"
                floated="left"
                style={{ borderRadius: 5 }}
              />
              <p style={{ fontSize: 22, textAlign: "justify" }}>
                Responsive communications and state-of-art administration system
                are the strength of our frontliners, to answer customer
                sexpectation for timely and satisfactory attention. Our Call
                Center is available to serve 24 hours and equipped with
                integrated system.
              </p>
            </Segment>
            <Segment
              style={{
                margin: "0em 0em 2em 5em",
                minHeight: 340,
                // textAlign: "justify"
              }}
            >
              <Header as="h2" floated="left" style={{ marginTop: 6 }}>
                Supply Chain Management
              </Header>
              {/* <Label
            color="orange"
            ribbon="right"
            size="big"
            style={{ margin: "auto", left: "74.5%" }}
          >
            <Rating icon="star" defaultRating={5} maxRating={5} disabled />
          </Label> */}
              <Divider clearing />
              <Image
                src={SV2}
                size="medium"
                floated="right"
                style={{ borderRadius: 5 }}
              />
              <p style={{ fontSize: 22, textAlign: "justify" }}>
                Our Supply Chain Management utilizes with the latest technology
                and system to simplify your needs in storing, managing, moving
                and inventorying your stock. We cultivate a web-based integrated
                system to serve all process such as ordering, tracing and all
                reporting. Through our competent logistics networks. We can
                ensure rapid inbound and outbound supply of in.
              </p>
            </Segment>
            <Segment
              style={{
                margin: "0em 5em 2em 0em",
                minHeight: 340,
                // textAlign: "justify"
              }}
              raised
            >
              <Header as="h2" floated="left" style={{ marginTop: 6 }}>
                IT for Inventory and Logistics Product
              </Header>
              {/* <Label
            color="orange"
            ribbon="right"
            size="big"
            style={{ margin: "auto", left: "63.2%" }}
          >
            <Rating icon="star" defaultRating={5} maxRating={5} disabled />
          </Label> */}
              {/* </div> */}

              <Divider clearing />
              <Image
                src={SV3}
                size="medium"
                floated="left"
                style={{ borderRadius: 5 }}
              />
              <p style={{ fontSize: 22, textAlign: "justify" }}>
                The creation of specialized inventory system, particulary in
                field of inventory and logistics. Using supply chain management
                close loop pattern, designing the trace to start from inbound,
                outbound, repair to replacement.
              </p>
            </Segment>
          </Container>
        </div>
      </Responsive>
    </>
  );
}

// const imageFrame = {
//   boxShadow: "0px 10px 30px -10px rgba(0, 0, 0, 0.3)"
// };
